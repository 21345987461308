.app {
  background: url("../assets/Images/hero1.jpeg") no-repeat;
  background-size: cover;
background-attachment: fixed;
background-position: center center;
  /* background-color: black; */
  height: 100vh;
  overflow: auto;
}

