.main-nav {
  color: white;
  opacity: 0.5;
}

.main-nav:hover {
  color: white;
  opacity: 1;
  text-decoration: none;
}

.main-nav-active {
  opacity: 1;
}

.navbar {
  z-index: 2;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
