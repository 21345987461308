@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.links {
  color: white;
  animation: fadeIn 5s;
}

.links:hover {
  color: grey;
}
